<template>
  <div>
    <div class="backgroundOV"></div>

    <v-card outlined>
      <v-card-title class="titleLarge">Hintergrundwissen</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card>
      <v-card-text>
        <p>
          In Abgrenzung zum Zahlenrechnen, bei welchem mit Zahlganzheiten
          umgegangen wird (siehe dazu <a href="/zahlenrechnen">Zahlenrechnen</a>), werden beim Ziffernrechnen
          die Ziffern des jeweiligen Stellenwerts betrachtet und nach
          festgelegten, algorithmischen Regeln miteinander verrechnet. Es wird
          beim Rechnen also nicht die ganze Zahl in den Blick genommen, sondern
          es werden nach festen Vorgehensweisen die einzelnen Stellenwerte
          (relativ) isoliert voneinander betrachtet (Padberg & Benz 2011, S.
          170). Obwohl das schriftliche Rechnen häufig algorithmisch angewendet
          wird, ist im Lernprozess essenziell, dass Kinder verstehen, warum sie
          so vorgehen können. Eine verständnisbasierte Erarbeitung ist für eine
          sichere und möglichst fehlerfreie Anwendung unerlässlich (Krauthausen,
          2018, S. 91).
        </p>
        <p>
          Exemplarisch wird im Folgenden der Fokus auf die Addition gelegt und
          zunächst beschrieben, was der Unterschied zwischen dem Rechnen mit
          Zahlganzheiten und dem Rechnen mit Ziffern ist. Dabei wird das
          Verfahren der schriftlichen Addition erklärt und anschließend wird
          gezeigt, wie das schriftliche Verfahren verständnisbasiert erarbeitet
          werden kann. Da es wichtig ist, dass Kinder nicht nur algorithmisch
          vorgehen, um Lösungen von Rechnungen zu erarbeiten, wird abschließend
          ein Einblick in den flexiblen Umgang mit verschiedenen Rechenmethoden
          gegeben. Dies bedeutet, dass Kinder je nach Aufgabe und Kontext
          entscheiden, wie sie eine Aufgabe berechnen – mündlich (im Kopf),
          halbschriftlich (unter Ausnutzung von Zahl- und Aufgabenbeziehungen)
          oder schriftlich (algorithmisch).
        </p>
        <br />
        <AppLiteraturZR />
      </v-card-text>
    </v-card>
    <AppBottomNavZR
      next="/ziffernrechnen/hintergrundwissen/verfahrensweisen"
      back="/ziffernrechnen"
    />
  </div>
</template>

<script>
import AppBottomNavZR from "@/common/AppBottomNavZR";
import AppLiteraturZR from "@/common/AppLiteraturZR";
export default {
  components: {
    AppBottomNavZR,
    AppLiteraturZR,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
